import React, { Fragment } from 'react';
import styledSystem from '@emotion/styled';
import LayoutWithAuth from '../components/layoutWithAuth';
import PasswordForgetForm from '../components/PasswordForget';
import { Box, Flex } from '../style/basicStyle';

const PasswordForgetPage = () => (
  <Box px={[2, 3, 5]}>
    <h1>Forgot Password</h1>
    <PasswordForgetForm />
  </Box>
);

export default () => (
  <Box py={[5]}>
    <LayoutWithAuth>
      <PasswordForgetPage />
    </LayoutWithAuth>
  </Box>
);
